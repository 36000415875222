@tailwind base;
@tailwind components;
@tailwind utilities;

.css-t3ipsp-control{
    border-color: #06bbac !important;
}
.css-12a83d4-MultiValueRemove:hover{
    background-color: #06bbac !important;
    color: white !important;
}